import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanelComponent } from './panel.component';
import { IconModule } from '../icon/icon.module';
import { ActivateElementModule } from '../../directives/activate-element/activate-tab-element.module';
import { ColorThemeModule } from '../shared/color-theme/color-theme.module';


@NgModule({
  declarations: [
    PanelComponent
  ],
  imports: [
    CommonModule,
    IconModule,
    ActivateElementModule,
  ],
  exports: [
    PanelComponent,
    ColorThemeModule,
  ]
})
export class PanelModule { }
